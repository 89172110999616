.login {
  height: 100vh;
  .blue-rectangle {
    z-index: 2;
    width: 100%;
    height: calc(100vh);
    background-image: radial-gradient(
      circle at 50% 50%,
      #0d11d6,
      rgba(0, 0, 0, 0) 50%
    );
  }
  .background-samsung {
    top: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: calc(100vh * 0.5);
    img {
      transform: rotate(180deg);
      height: 100%;
      width: 100%;
    }
  }
  .background-samsung-bottom {
    bottom: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: calc(100vh * 0.5);
    img {
      height: 100%;
      width: 100%;
    }
  }

  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: #09101d;

  position: relative;
  justify-content: center;

  .login-content {
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  .samsung-logo {
    height: 24px;
    margin-bottom: 100px;
  }

  .logo-5-g-series {
    height: 74px;
    margin-bottom: 70px;
  }

  .present-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.11px;
    text-align: center;
    color: #fff;
  }

  .info-text {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: 0.19px;
    text-align: center;
    color: #fff;
    margin-bottom: 12px;
  }

  .description-text {
    margin-top: 80px;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.13px;
    text-align: center;
    color: #fff;
  }

  .login_button {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.11px;
    text-align: center;
    color: #fff;

    border-right: 1px solid #000;
    background: #ff4b03;
    border-radius: 8px;
    height: 45px;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 45px;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    img {
      margin-left: 5px;
      width: 60px;
    }
    margin-bottom: 16px;
  }
}

// @media only screen and (min-width: 64em) {
//   .login {
//     background: url('../../assets/images/loginweb.jpg');
//   }
// }

@media only screen and (min-width: 64em) {
  .login {
  }
}
