.tabs {
  padding: 0 15px;

  .tabs-container {
    display: flex;
    white-space: nowrap;
    overflow: auto;
    border-bottom: solid 1px #fff;
  }

  .tab {
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 15px 9px 15px;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.7 !important;
    }
    &.selected {
      border-bottom: 4px solid #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

@media only screen and (min-width: 64em) {
  .tabs {
    .tab {
      font-size: 15px;
      height: 83px;

      img {
        height: 40px;
      }
    }
  }
}
