.race {
  padding: 20px 15px;
  padding-bottom: 50px;
  .race-team-ranking {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 20px;
    .team-ranking-item {
      display: flex;
      flex-direction: column;

      .team-img-container {
        position: relative;
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        border: 5px solid;
        border-radius: 50%;
        transform: rotate(45deg);
        &.red {
          border-color: transparent #eb523f #eb523f transparent;
        }
        &.blue-light {
          border-color: transparent #50b0de #50b0de transparent;
        }
        &.green {
          border-color: transparent #4ecc8d #4ecc8d transparent;
        }
        &.blue-dark {
          border-color: transparent #0e11d5 #0e11d5 transparent;
        }
        &.black {
          border-color: transparent #000 #000 transparent;
        }
        &.yellow {
          border-color: transparent #f4b85d #f4b85d transparent;
        }
      }

      .team-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transform: rotate(-45deg);
      }
      .prize {
        position: absolute;
        width: 44px;
        height: 29px;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.8;
        letter-spacing: 0.28px;
        transform: rotate(-45deg);
        bottom: -20px;
        left: 12px;
      }
      .team-points {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.17px;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        color: #fff;
        span {
          font-size: 11px;
          font-weight: normal;
          letter-spacing: 0.1px;
          color: #fff;
        }
      }
    }
  }
  .race-group-ranking {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
