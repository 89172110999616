.group {
  .group-header {
    margin-bottom: 10px;
    cursor: pointer;
    // &:hover {
    //   opacity: 0.7;
    // }
    &.open {
      border: solid 1px #fff;
      .order-container {
        background: #fff;
        .order-time {
          color: #2e32e8;
          span {
            color: #2e32e8;
          }
        }
        .order-text {
          color: #2e32e8;
        }
      }
    }
    display: flex;
    justify-content: space-between;
    border-radius: 7px;
    background: #28303f;
    align-items: center;
    position: relative;
    gap: 2px;

    .down-icon-container {
      width: 25px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;
      .down-icon {
        right: 10px;
        width: 13px;
      }
    }

    .order-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #2e38d7;
      min-height: 79px;
      .order-time {
        font-size: 9px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.08px;
        text-align: center;
        color: #fff;
        span {
          font-size: 6px;
          letter-spacing: 0.06px;
          color: #fff;
        }
      }
      .order-text {
        font-size: 35px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.33px;
        text-align: center;
        color: #fff;
      }
    }

    .athletes-container {
      display: flex;
      overflow: hidden;
      gap: 2px;
      align-items: center;
      .athlete-photo-container {
        width: 35px;
        height: 35px;
        border: solid 1px #fff;

        position: relative;
        border: 5px solid;
        border-radius: 50%;
        transform: rotate(45deg);

        &.red {
          border-color: transparent transparent #eb523f #eb523f;
        }
        &.blue-light {
          border-color: transparent transparent #50b0de #50b0de;
        }
        &.green {
          border-color: transparent transparent #4ecc8d #4ecc8d;
        }
        &.blue-dark {
          border-color: transparent transparent #0e11d5 #0e11d5;
        }
        &.yellow {
          border-color: transparent transparent #f4b85d #f4b85d;
        }
        &.black {
          border-color: transparent transparent #000 #000;
        }

        .athlete-photo {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .group-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
    .watch-icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
    .flag-icon-container {
      width: 30px;
      height: 30px;
    }
    .flag-icon {
      width: 25px;
      height: 25px;
      object-fit: fill;
    }
    .flag-icon-green {
      width: 30px;
      height: 30px;
      object-fit: fill;
    }
    .segment-tab {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: solid 1.5px #fff;

      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.85;
      letter-spacing: 0.12px;
      color: #fff;

      &.selected {
        background-color: #2ee83a;
        color: #09101d;
      }
    }
    .first-segment {
      width: 100%;
      height: 9px;
      border-top-left-radius: 4.5px;
      border-bottom-left-radius: 4.5px;
      border: solid 1.5px #fff;
      &.selected {
        background: #fff;
      }
    }
    .medium-segment {
      width: 100%;
      height: 9px;
      border: solid 1.5px #fff;
      &.selected {
        background: #fff;
      }
    }
    .last-segment {
      width: 100%;
      height: 9px;
      border-top-right-radius: 4.5px;
      border-bottom-right-radius: 4.5px;
      border: solid 1.5px #fff;
      &.selected {
        background: #fff;
      }
    }
  }
  .athlete-ranking-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .athlete-ranking-item {
      display: flex;
      border-bottom: solid 1.5px #858585;
      position: relative;
      overflow-x: auto;
      .athlete-ranking-photos {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        padding-right: 10px;
        &.red {
          border-bottom: solid 5px #eb523f;
        }
        &.blue-light {
          border-bottom: solid 5px #50b0de;
        }
        &.green {
          border-bottom: solid 5px #4ecc8d;
        }
        &.blue-dark {
          border-bottom: solid 5px #0e11d5;
        }
        &.black {
          border-bottom: solid 5px #000;
        }
        &.yellow {
          border-bottom: solid 5px #f4b85d;
        }

        p {
          font-size: 25px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.8;
          letter-spacing: 0.28px;
          color: #fff;
          margin-right: 10px;
        }
        .team-img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          border: solid 1px #fff;
        }
        .athlete-photo {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          border: solid 1px #fff;
          margin-left: -20px;
        }
      }
    }
    .athlete-name-size-container {
      width: 120px;
    }
    .athlete-name-size {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-bottom: 10px;
      overflow: hidden;
    }
    .athlete-name-container {
      margin-left: 2px;
      text-align: start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .athlete-name {
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.14px;
        color: #fff;
      }
      .athlete-points {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.14px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.08px;
        color: #fff;
        span {
          font-size: 9px;
          font-weight: normal;
          letter-spacing: 0.08px;
        }
      }
    }
    .prize-container {
      margin-right: 10px;
      margin-left: 5px;
      width: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      .prize {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.3px;
      }
    }
    .time-container {
      width: 91px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      .time-flag {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 15px;
        border-radius: 24px;
        height: 30px;
        &.first {
          background-color: #2e32e8;
        }
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.15px;
        color: #fff;
        div {
          white-space: nowrap;
        }
      }
    }
  }
}
