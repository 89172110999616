/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Samsung Sharp Sans';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/samsungsharpsans.woff') format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans';
  font-style: normal;
  font-weight: medium;
  src: url('fonts/samsungsharpsans-medium.woff') format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans';
  font-style: normal;
  font-weight: bold;
  src: url('fonts/samsungsharpsans-bold.woff') format('woff');
}
