.team {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .team-header {
    &.red {
      border-left: solid 7px #eb523f;
    }
    &.blue-light {
      border-left: solid 7px #50b0de;
    }
    &.green {
      border-left: solid 7px #4ecc8d;
    }
    &.blue-dark {
      border-left: solid 7px #0e11d5;
    }
    &.black {
      border-left: solid 7px #000;
    }
    &.yellow {
      border-left: solid 7px #f4b85d;
    }
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &.open {
      border-right: solid 1px #fff;
      border-top: solid 1px #fff;
      border-bottom: solid 1px #fff;
    }
    display: flex;
    padding: 10px;
    border-radius: 7px;
    background: #28303f;
    align-items: center;
    position: relative;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 20px;
    }

    .down-icon {
      right: 1px;
      position: absolute;
      width: 13px;
    }

    .team-name {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: #fff;
    }
  }

  .athlete {
    display: flex;
    border-bottom: solid 1.5px #858585;
    padding: 0 10px 10px 20px;
    align-items: center;
    position: relative;
    &.stravaId {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    .athlete-photo {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 20px;
      border: solid 1px #fff;
      object-fit: cover;
    }

    .strava-icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      right: 15px;
      position: absolute;
    }

    .athlete-photo {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 20px;
      border: solid 1px #fff;
    }

    .athlete-name {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.14px;
      color: #fff;
    }
  }
}
